var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showOverlay}},[_c('validation-observer',{ref:"createGameForm",attrs:{"tag":"div"}},[_c('b-card',{staticClass:"data-edit-wrapper"},[_c('h2',[_vm._v("Game Details")]),_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"data-edit-seoTitle","state":errors.length > 0 ? false:null},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Short Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"data-edit-seoTitle","state":errors.length > 0 ? false:null},model:{value:(_vm.data.shortDescription),callback:function ($$v) {_vm.$set(_vm.data, "shortDescription", $$v)},expression:"data.shortDescription"}})]}}])})],1)],1),(_vm.platforms)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Platforms"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'border-danger rounded':errors.length > 0 ? true:null},attrs:{"options":_vm.platforms,"reduce":function (name) { return name.id; },"dir":"ltr","label":"name","multiple":""},model:{value:(_vm.data.platforms),callback:function ($$v) {_vm.$set(_vm.data, "platforms", $$v)},expression:"data.platforms"}})]}}],null,false,1962436941)})],1)],1):_vm._e(),_c('b-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Main Picture"}},[_c('MediaHandler',{ref:"mediaHandler",on:{"sendData":_vm.setFiles}})],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Slider Picture"}},[_c('MediaHandler',{ref:"mediaHandlerSlider",on:{"sendData":_vm.setFilesSlider}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Full Description"}},[_c('ckEditorMain',{ref:"editorContent",attrs:{"defaultContent":_vm.data.fullDescription},on:{"getEditorContent":_vm.setEditorContent}})],1)],1)],1)],1)],1),_c('b-card',[_c('b-row',[(_vm.data)?_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('h2',[_vm._v("SEO")])]):_vm._e(),_c('b-col',{attrs:{"md":"4","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Seo Title"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"data-edit-seoTitle","state":errors.length > 0 ? false:null},model:{value:(_vm.data.seoTitle),callback:function ($$v) {_vm.$set(_vm.data, "seoTitle", $$v)},expression:"data.seoTitle"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Seo Url"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"data-edit-seoTitle","state":errors.length > 0 ? false:null},model:{value:(_vm.allCharactersToDash),callback:function ($$v) {_vm.allCharactersToDash=$$v},expression:"allCharactersToDash"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Seo Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"data-edit-seoTitle","state":errors.length > 0 ? false:null},model:{value:(_vm.data.seoDescription),callback:function ($$v) {_vm.$set(_vm.data, "seoDescription", $$v)},expression:"data.seoDescription"}})]}}])})],1)],1),_c('b-col',{staticClass:"pl-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"col-md-12 "},[_c('div',{staticClass:"serp-preview d-lg-flex d-none flex-column align-items-start"},[_c('div',{staticClass:"serp-title"},[_c('h4',{staticStyle:{"color":"rgb(26, 13, 171)!important"}},[_vm._v(" "+_vm._s(_vm.data.seoTitle)+" ")])]),_c('div',{staticClass:"serp-url"},[_c('h5',{staticClass:"d-flex align-items-center flex-wrap",staticStyle:{"color":"rgb(0, 102, 33)!important"},attrs:{"dir":"ltr"}},[_c('span',[_vm._v("https://skycoach.com/"),_c('span',{staticClass:"serp-arrow"})]),_c('span',{staticStyle:{"overflow-wrap":"anywhere"}},[_vm._v(" "+_vm._s(_vm.data.seourl.replaceAll(' ', '-'))+" ")])])]),_c('div',{staticClass:"serp-description"},[_c('h6',{staticStyle:{"overflow-wrap":"anywhere"}},[_vm._v(" "+_vm._s(_vm.data.seoDescription)+" ")])])]),_c('div',{staticClass:"serp-preview d-lg-none  p-1 d-flex flex-column align-items-start"},[_c('div',{staticClass:"serp-title"},[_c('h6',[_vm._v(" "+_vm._s(_vm.data.seoTitle)+" ")])]),_c('div',{staticClass:"serp-url d-flex align-items-center flex-wrap"},[_c('small',[_vm._v(_vm._s(_vm.data.seourl.replaceAll(' ', '-'))+"/")]),_c('small',[_vm._v(" https://skycoach.com/ "),_c('small',{staticClass:"serp-arrow"})])]),_c('div',{staticClass:"serp-description"},[_c('small',[_vm._v(" "+_vm._s(_vm.data.seoDescription)+" ")])])])])]),_c('b-col',{attrs:{"cols":"3"}},[_c('b-button',{staticClass:"btn-tour-skip mr-1",attrs:{"variant":"primary"},on:{"click":_vm.createGame}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Submit")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }