import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllPlatforms extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Platform')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class GetAllPlatformsByPage extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Platform/GetByPage')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class CreatePlatform extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Platform')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class UpdatePlatform extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Platform')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }

  setParamsQuery(data) {
    super.setRequestParam(data)
  }
}

class DeletePlatform extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Platform')
  }

  setParams(data) {
    super.setTag(`Platform/${data.id}`)

    // super.setRequestParam(data)
  }
}

export {
  DeletePlatform,
  UpdatePlatform,
  CreatePlatform,
  GetAllPlatforms,
  GetAllPlatformsByPage

}
